<template>
  <Breadcrumbs primary="Личный кабинет" secondary="Настройки"/>
  <el-tabs v-model="visiblePanel" class="primary-heading-tabs">
    <el-tab-pane label="Уведомления и ограничения" name="settings">
      <TableSpinner :loading="dataLoading">

        <el-form label-position="top">
          <h4 class="report-parameters-title">Где уведомлять</h4>
          <el-row :gutter="40" style="margin-top: 20px; ">
            <el-col :md="12" :xs="24">
              <label class="el-form-item__label  black" >
                Канал получения уведомлений
                <el-popover
                    placement="bottom-start"
                    title="Канал получения уведомлений"
                    :width="400"
                    trigger="hover"
                >
                  <template #reference>
                    <font-awesome-icon icon="question-circle"
                                       style="margin-left: 10px; font-size: 16px; cursor: pointer"/>
                  </template>
                  <p style="word-break: break-word">
                    Выберите, как вы хотите получать уведомления от платформы. Если вы хотите получать уведомления в
                    телеграм-боте, подпишитесь на него в соседней вкладке "Системный Телеграм-бот"
                  </p>
                </el-popover>
              </label>
              <el-select class="full-width" v-model="notificationChannel">
                <el-option label="Е-мейл" value="email"/>
                <el-option label="Телеграм-бот" value="telegram"/>
                <el-option label="Е-мейл + Телеграм-бот" value="email_telegram"/>
              </el-select>

            </el-col>
            <el-col :md="12" :xs="24">
              <label class="el-form-item__label black">Емайл адреса
                уведомлений <span style="color:#727272">&nbsp;(через запятую)</span></label>
              <el-form-item label="" class="small-bottom-margin-mid-screen">
                <el-input v-model="notificationEmails"/>
              </el-form-item>

            </el-col>

          </el-row>

          <el-divider class="blue-divider" style="margin-bottom: 40px"/>

          <h4 class="report-parameters-title">Ограничения и уведомления</h4>
          <div style=" border: 1px solid #eee;padding: 20px; margin-bottom: 30px">
            <el-row>
              <el-col :span="24">
                <el-switch v-model="enableTimeLimitation"></el-switch>
                <label class="el-form-item__label  black" style="display: inline; margin-left: 20px">
                  Ограничение времени отправки
                  <el-popover
                      placement="bottom-start"
                      title="Ограничение времени отправки"
                      :width="400"
                      trigger="hover"
                  >
                    <template #reference>
                      <font-awesome-icon icon="question-circle"
                                         style="margin-left: 10px; font-size: 16px; cursor: pointer"/>
                    </template>
                    <p style="word-break: break-word">
                      Вне указанного периода действия данного ограничения все смс, отправленные через Ваш аккаунт, блокируются и не
                      тарифицируются.
                      Отчёт о заблокированных смс доступен в разделе: Системные отчёты -> Журнал событий
                    </p>
                  </el-popover>
                </label>
              </el-col>
            </el-row>
            <el-row v-show="enableTimeLimitation" style="margin-top: 20px;">
              <el-col :span="24">
                <el-row :gutter="40" v-show="enableTimeLimitation">
                  <el-col :md="12" :xs="24">
                    <el-form-item label="С:" style="margin-bottom: 0px">
                      <el-time-picker
                          style="width: 100%"
                          v-model="timeRestrictionFrom"
                          format="HH:mm"
                      />

                    </el-form-item>
                  </el-col>
                  <el-col :md="12" :xs="24">
                    <el-form-item label="До:" style="margin-bottom: 0px" class="small-top-margin-mid-screen">
                      <el-time-picker
                          style="width: 100%"
                          v-model="timeRestrictionTo"
                          format="HH:mm"
                      />
                    </el-form-item>

                  </el-col>
                </el-row>
              </el-col>
            </el-row>
          </div>


          <div style=" border: 1px solid #eee;padding: 20px; margin-bottom: 30px">
            <el-row>
              <el-col :span="24">
                <el-switch v-model="enableSameMessageLimitation"></el-switch>
                <label class="el-form-item__label  black" style="display: inline; margin-left: 20px">
                  Запрет одинаковых
                  сообщений одному адресату в заданный
                  интервал
                  <el-popover
                      placement="bottom-start"
                      title="Запрет одинаковых сообщений одному адресату в заданный интервал"
                      :width="400"
                      trigger="hover"
                  >
                    <template #reference>
                      <font-awesome-icon icon="question-circle"
                                         style="margin-left: 10px; font-size: 16px; cursor: pointer"/>
                    </template>
                    <p style="word-break: break-word">
                      Блокирует все смс с одинаковым текстом, на 1 номер телефона в указанный промежуток времени.
                      Позволяет блокировать дубли сообщений в случае сложной настройки производимых рассылок.
                    </p>
                  </el-popover>
                </label>
              </el-col>
            </el-row>
            <el-row v-show="enableSameMessageLimitation" style="margin-top: 20px;">
              <el-col :span="24">
                <el-form-item style="margin-bottom: 0px" label="" v-show="enableSameMessageLimitation">
                  <el-input v-model="sameMessageRestriction">
                    <template #append>сек.</template>
                  </el-input>
                </el-form-item>

              </el-col>
            </el-row>
          </div>


          <div style=" border: 1px solid #eee;padding: 20px; margin-bottom: 30px">
            <el-row>
              <el-col :span="24">
                <el-switch v-model="enableMaxMessagesLimitation"></el-switch>
                <label class="el-form-item__label  black" style="display: inline; margin-left: 20px">
                  Максимальное количество сообщений 1 абоненту в сутки
                  <el-popover
                      placement="bottom-start"
                      title="Максимальное количество сообщений 1 абоненту в сутки"
                      :width="410"
                      trigger="hover"
                  >
                    <template #reference>
                      <font-awesome-icon icon="question-circle"
                                         style="margin-left: 10px; font-size: 16px; cursor: pointer"/>
                    </template>
                    <p style="word-break: break-word">
                      Все смс сверх лимита будут заблокированы.
                      Отчёт о заблокированных смс доступен в разделе: Системные отчёты -> Журнал событий.
                      Используется для защиты системы регистраций от эмуляции регистраций.
                    </p>
                  </el-popover>
                </label>
              </el-col>
            </el-row>
            <el-row v-show="enableMaxMessagesLimitation" style="margin-top: 20px;">
              <el-col :span="24">
                <el-form-item style="margin-bottom: 0px" label="">
                  <el-input v-model="dailyLimit">
                    <template #append>сообщ.</template>
                  </el-input>
                </el-form-item>
              </el-col>
            </el-row>
          </div>


          <div style=" border: 1px solid #eee;padding: 20px; margin-bottom: 30px">
            <el-row>
              <el-col :span="24">
                <el-switch v-model="enableDailySpendingLimitation"></el-switch>
                <label class="el-form-item__label  black" style="display: inline; margin-left: 20px">
                  Суточное ограничение затрат на отправку смс
                  <el-popover
                      placement="bottom-start"
                      title="Суточное ограничение затрат на отправку смс"
                      :width="400"
                      trigger="hover"
                  >
                    <template #reference>
                      <font-awesome-icon icon="question-circle"
                                         style="margin-left: 10px; font-size: 16px; cursor: pointer"/>
                    </template>
                    <p style="word-break: break-word">
                      Позволяет вводить лимиты ограничений на ежедневные затраты.
                      Используется как для защиты от эмуляции регистраций, так и для планового лимитирования.
                    </p>
                  </el-popover>
                </label>
              </el-col>
            </el-row>
            <el-row v-show="enableDailySpendingLimitation" style="margin-top: 20px; ">
              <el-col :span="24">
              <span
                  class="el-form-item__label">Расход за текущие сутки (по МСК):&nbsp;<b>{{
                  parseFloat(todaysSpendings).toFixed(2)
                }} р.</b></span>
                <span
                    class="el-form-item__label">Средний расход за посление 10 суток:&nbsp;<b>{{
                    parseFloat(tenDaysAverageSpending).toFixed(2)
                  }} р.</b></span>
                <el-divider/>
                <el-form-item style="margin-bottom: 0px" label="Тип ограничения">
                  <el-select v-model="dailySpendingLimitationType" style="width: 100%">
                    <el-option value="1" label="Фиксированное"></el-option>
                    <el-option value="2" label="Статистическое"></el-option>
                  </el-select>
                </el-form-item>
                <el-form-item style="margin-top: 20px" label="Порог суточных затрат"
                              v-show="dailySpendingLimitationType === '1'">
                  <el-input v-model="dailySpendingLimitationAbsolute">
                    <template #append>руб.</template>
                  </el-input>
                </el-form-item>
                <el-form-item style="margin-top: 20px"
                              label="Максимальное отклонение от средних затрат за последние 10 дней"
                              v-show="dailySpendingLimitationType === '2'">
                  <el-input v-model="dailySpendingLimitationRelative">
                    <template #append>%.</template>
                  </el-input>
                </el-form-item>

                <!--                <el-form-item label="Емайл адреса уведомлений (через запятую)">-->
                <!--                  <el-input v-model="dailySpendingNotificationEmails"/>-->
                <!--                </el-form-item>-->

              </el-col>
            </el-row>
          </div>

          <div style=" border: 1px solid #eee;padding: 20px; margin-bottom: 30px">
            <el-row>
              <el-col :span="24">
                <el-switch v-model="enableUnpaidMessagesNotification"></el-switch>
                <label class="el-form-item__label  black" style="display: inline; margin-left: 20px">
                  Уведомлять о появлении смс ожидающих оплаты
                  <el-popover
                      placement="bottom-start"
                      title="Уведомлять о появлении смс ожидающих оплаты"
                      :width="420"
                      trigger="hover"
                  >
                    <template #reference>
                      <font-awesome-icon icon="question-circle"
                                         style="margin-left: 10px; font-size: 16px; cursor: pointer"/>
                    </template>
                    <p style="word-break: break-word">
                      Для получения уведомлений, если на Вашу рассылку через личный кабинет не хватило баланса.
                    </p>
                  </el-popover>
                </label>
              </el-col>
            </el-row>
            <!--            <el-row v-show="enableUnpaidMessagesNotification" style="margin-top: 20px; ">-->
            <!--              <el-col :span="24">-->
            <!--                <label class="el-form-item__label black" style="padding-bottom: 0px; display:block">Емайл адреса-->
            <!--                  уведомлений <span style="color:#727272">&nbsp;(через запятую)</span></label>-->
            <!--                <el-form-item label="" class="small-bottom-margin-mid-screen">-->
            <!--                  <el-input v-model="unpaidMessagesNoticeEmails"/>-->
            <!--                </el-form-item>-->
            <!--              </el-col>-->
            <!--            </el-row>-->
          </div>


          <div style=" border: 1px solid #eee;padding: 20px">
            <el-row>
              <el-col :span="24">
                <el-switch v-model="enableLowBalanceNotifications"></el-switch>
                <label class="el-form-item__label  black" style="display: inline; margin-left: 20px">
                  Уведомлять о низком балансе
<!--                  <el-popover-->
<!--                      placement="bottom-start"-->
<!--                      title="Уведомлять о появлении смс ожидающих оплаты"-->
<!--                      :width="420"-->
<!--                      trigger="hover"-->
<!--                  >-->
<!--                    <template #reference>-->
<!--                      <font-awesome-icon icon="question-circle"-->
<!--                                         style="margin-left: 10px; font-size: 16px; cursor: pointer"/>-->
<!--                    </template>-->
<!--                    <p style="word-break: break-word">-->
<!--                      При включенной функции "Отправлять уведомления по смс" и активной подписке на наш Telegram-бот в в разделе "Отправлять уведомления по смс" сообщения о низком балансе будут отправляться Вам в Telegram-->
<!--                    </p>-->
<!--                  </el-popover>-->
                </label>
              </el-col>
            </el-row>
            <el-row :gutter="40" v-show="enableLowBalanceNotifications" style="margin-top: 20px; ">
              <el-col :md="12" :xs="24">
                <el-form-item label="Порог уведомления о низком уровне баланса" class="small-bottom-margin-mid-screen">
                  <el-input v-model="lowBalanceNotice"/>
                </el-form-item>
                <el-form-item label="Частота уведомлений" class="small-bottom-margin-mid-screen">
                  <el-select v-model="lowBalanceNoticeInterval" class="full-width">
                    <el-option label="Никогда" value=""></el-option>
                    <el-option label="Однократно" value="0"></el-option>
                    <el-option label="Ежедневно" value="1"></el-option>
                    <el-option label="Еженедельно" value="2"></el-option>
                  </el-select>
                </el-form-item>
              </el-col>
              <el-col :md="12" :xs="24">
                <el-form-item label="Отправлять уведомления по смс" class="small-bottom-margin-mid-screen"
                              style="margin-bottom: 28px">
                  <el-switch v-model="sendNotificationsViaSms"></el-switch>
                </el-form-item>

                <el-form-item label="Телефонные
                  номера уведомлений"
                              v-show="sendNotificationsViaSms"
                >
                  <el-input v-model="lowBalanceNoticePhones"/>

                  <span style="margin-top: 5px; color: var(--el-text-color-placeholder); font-size: 14px">(через запятую, тарификация по назначенным тарифам)</span>
                </el-form-item>
              </el-col>
            </el-row>

          </div>
          <el-divider class="blue-divider" style="margin-bottom: 40px"/>


          <h4 class="report-parameters-title">Прочие настройки</h4>
          <el-row v-show="!$store.getters.user.isManager()">
            <el-col :span="24">
              <el-form-item label="Часовой пояс">
                <el-select v-model="utcTimeShift" style="width: 100%">
                  <el-option value="-720" label="UTC-12 — Линия перемены даты (Yankee)"></el-option>
                  <el-option value="-660" label="UTC-11 — Американское Самоа (X-ray)"></el-option>
                  <el-option value="-600" label="UTC-10 — Гавайи (Whiskey)"></el-option>
                  <el-option value="-540" label="UTC-9 — Аляска (Victor)"></el-option>
                  <el-option value="-480"
                             label="UTC-8 — Североамериканское тихоокеанское время (США и Канада) (Uniform)"></el-option>
                  <el-option value="-420"
                             label="UTC-7 — Горное время (США и Канада), Мексика (Чиуауа, Ла-Пас, Масатлан)(Tango)"></el-option>
                  <el-option value="-360"
                             label="UTC-6 — Центральное время (США и Канада), Центральноамериканское время, Мексика (Гвадалахара, Мехико, Монтеррей) (Sierra)"></el-option>
                  <el-option value="-300"
                             label="UTC-5 — Североамериканское восточное время (США и Канада), Южноамериканское тихоокеанское время (Богота, Лима, Кито) (Romeo)"></el-option>
                  <el-option value="-270" label="UTC-4:30 — Каракас"></el-option>
                  <el-option value="-240"
                             label="UTC-4 — Атлантическое время (Канада), Ла-Пас, Сантьяго) (Quebec)"></el-option>
                  <el-option value="-210" label="UTC-3:30 — Ньюфаундленд"></el-option>
                  <el-option value="-180"
                             label="UTC-3 — Южноамериканское восточное время (Бразилиа, Буэнос-Айрес, Джорджтаун), Гренландия (Papa)"></el-option>
                  <el-option value="-120" label="UTC-2 — Среднеатлантическое время (Oscar)"></el-option>
                  <el-option value="-60" label="UTC-1 — Азорские острова, Кабо-Верде (November)"></el-option>
                  <el-option value="0"
                             label="UTC+0 — Западноевропейское время (Дублин, Эдинбург, Лиссабон, Лондон, Касабланка, Монровия) (Zulu)"></el-option>
                  <el-option value="60"
                             label="UTC+1 — Центральноевропейское время (Амстердам, Берлин, Берн, Брюссель, Вена, Копенгаген, Мадрид, Париж, Рим, Стокгольм, Белград, Братислава, Будапешт, Варшава, Любляна, Прага, Сараево, Скопье, Загреб) Западное центральноафриканское время (Alpha) "></el-option>
                  <el-option value="120" label="UTC+2 — Калининградское время (Калининград)"></el-option>
                  <el-option value="180" label="UTC+3 — Московское время"></el-option>
                  <el-option value="210" label="UTC+3:30 — Тегеранское время"></el-option>
                  <el-option value="240" label="UTC+4 — Ижевск, Самара"></el-option>
                  <el-option value="270" label="UTC+4:30 — Афганистан"></el-option>
                  <el-option value="300" label="UTC+5 — Екатеринбургское время"></el-option>
                  <el-option value="330" label="UTC+5:30 — Индия, Шри-Ланка"></el-option>
                  <el-option value="345" label="UTC+5:45 — Непал"></el-option>
                  <el-option value="360" label="UTC+6 — Омское время, Новосибирск, Кемерово"></el-option>
                  <el-option value="390" label="UTC+6:30 — Мьянма"></el-option>
                  <el-option value="420" label="UTC+7 — Красноярское время"></el-option>
                  <el-option value="480" label="UTC+8 — Иркутское время"></el-option>
                  <el-option value="540" label="UTC+9 — Якутское время"></el-option>
                  <el-option value="570"
                             label="UTC+9:30 — Центральноавстралийское время (Аделаида, Дарвин)"></el-option>
                  <el-option value="600" label="UTC+10 — Владивостокское время"></el-option>
                  <el-option value="660" label="UTC+11 — Магаданское время"></el-option>
                  <el-option value="720" label="UTC+12:00 - Камчатское край, Чукотский автономный округ"></el-option>
                  <el-option value="780" label="UTC+13 — Самоа, Тонга"></el-option>
                  <el-option value="840" label="UTC+14 — Острова Лайн (Кирибати)"></el-option>

                </el-select>
              </el-form-item>
              <el-form-item label="Имя отправителя по умолчанию">
                <el-select v-model="defaultSenderName" style="width: 100%">
                  <el-option
                      v-for="(name, i) in senderNamesOpts"
                      :label="name.label"
                      :key="i"
                      :value="name.value"
                  />
                </el-select>
              </el-form-item>
              <el-form-item label="URL скрипта для приёма статуса отправленных SMS">
                <el-input v-model="statusReceiveUrl"/>
              </el-form-item>
              <el-form-item label="URL скрипта для приёма входящих
                  сообщений">
                <el-input v-model="inboxReceiveUrl"/>
              </el-form-item>

            </el-col>
          </el-row>

        </el-form>

        <el-button type="success" :loading="saving" @click="saveSettings" style="margin-top: 20px">Сохранить</el-button>
      </TableSpinner>
    </el-tab-pane>
    <el-tab-pane label="Системный Telegram-бот" name="tgBot">
      <tg-bot/>
    </el-tab-pane>
  </el-tabs>
</template>

<script>
import moment from "moment"
import TgBot from "@shared/components/lk/tgBot.component.vue";


export default {
  name: "Settings",
  title: "Настройки",
  components: {
    TgBot
  },
  mounted() {
    if(this.$route.hash === "#tg_bot") this.visiblePanel = "tgBot"
    this.refresh()

  },
  computed: {},
  methods: {
    refresh() {
      this.dataLoading = true
      let p1 = this.axios.get("/sender_names/myNames?activeOnly=true")
          .then(resp => {
            this.senderNamesOpts = resp.data.map(s => {
              return {label: s.name, value: s._id}
            })
            this.defaultSenderName = (resp.data.find(n => n.active === 1 && n.default === 1 && !n.forCalls) || {_id: ""})._id
          })
      let p2 = this.axios.get("/user/getMySettings")
          .then(resp => {
            let settings = resp.data.settings
            this.timeRestrictionFrom = moment(settings.time_restriction_from, "HH:mm").toDate()
            this.timeRestrictionTo = moment(settings.time_restriction_to, "HH:mm").toDate()
            this.enableTimeLimitation = String(settings.time_restriction_enabled) === '1'

            this.sameMessageRestriction = settings.same_message_restriction
            this.enableSameMessageLimitation = String(settings.same_message_restriction_to_one_receiver) === '1'

            this.enableDailySpendingLimitation = settings.day_spending_limit && settings.day_spending_limit !== "0"
            this.dailySpendingLimitationType = !this.enableDailySpendingLimitation ? "1" : settings.day_spending_limit
            this.dailySpendingLimitationAbsolute = settings.day_spending_limit_abs
            this.dailySpendingLimitationRelative = settings.day_spending_limit_rel
            // this.dailySpendingNotificationEmails = settings.day_spending_notification_emails || ""

            this.utcTimeShift = String(resp.data.info.utcTimeShift)
            this.dailyLimit = settings.dailySendingLimit
            this.enableMaxMessagesLimitation = String(settings.enable_daily_limit) === '1'

            this.statusReceiveUrl = settings.url_receive_sms_status
            this.inboxReceiveUrl = settings.url_receive_inbox_sms

            this.enableLowBalanceNotifications = settings.enable_low_balance_notifications
            this.lowBalanceNotice = settings.critical_balance_notice
            this.lowBalanceNoticeInterval = settings.notice_interval
            // this.lowBalanceNoticeEmails = settings.critical_balance_notice_emails
            this.lowBalanceNoticePhones = settings.critical_balance_notice_phones
            this.sendNotificationsViaSms = String(settings.send_sms_balance_notifications) === '1'

            this.enableUnpaidMessagesNotification = settings.enableUnpaidMessagesNotification || false

            // this.unpaidMessagesNoticeEmails = (settings.unpaidMessagesNoticeEmails || []).join(",")
            this.tenDaysAverageSpending = resp.data.spending.average || 0
            this.todaysSpendings = resp.data.spending.today || 0

            this.notificationEmails = settings.notificationEmails.join(",")
            this.notificationChannel = settings.notificationChannel

          })
      Promise.all([p1, p2]).then(() => this.dataLoading = false)
    },
    saveSettings() {
      this.saving = true
      this.axios.post("/user/saveMySettings", {
        time_restriction_from: moment(this.timeRestrictionFrom).format("HH:mm"),
        time_restriction_to: moment(this.timeRestrictionTo).format("HH:mm"),
        time_restriction_enabled: this.enableTimeLimitation ? '1' : '0',
        same_message_restriction: this.sameMessageRestriction,
        same_message_restriction_to_one_receiver: this.enableSameMessageLimitation ? '1' : '0',
        dailySendingLimit: this.dailyLimit,
        utcTimeShift: this.utcTimeShift,
        enable_daily_limit: this.enableMaxMessagesLimitation ? '1' : '0',
        url_receive_sms_status: this.statusReceiveUrl,
        url_receive_inbox_sms: this.inboxReceiveUrl,

        enable_low_balance_notifications: this.enableLowBalanceNotifications,
        critical_balance_notice: this.lowBalanceNotice,
        notice_interval: this.lowBalanceNoticeInterval,
        // critical_balance_notice_emails: this.lowBalanceNoticeEmails,
        critical_balance_notice_phones: this.lowBalanceNoticePhones,
        send_sms_balance_notifications: this.sendNotificationsViaSms ? '1' : 0,


        day_spending_limit: this.enableDailySpendingLimitation ? this.dailySpendingLimitationType : "0",
        day_spending_limit_abs: this.enableDailySpendingLimitation ? Number(this.dailySpendingLimitationAbsolute) || 0 : 0,
        day_spending_limit_rel: this.enableDailySpendingLimitation ? Number(this.dailySpendingLimitationRelative) || 0 : 0,
        // day_spending_notification_emails: this.dailySpendingNotificationEmails || "",
        default_sender_name: this.defaultSenderName,

        enableUnpaidMessagesNotification: this.enableUnpaidMessagesNotification,
        // unpaidMessagesNoticeEmails: this.unpaidMessagesNoticeEmails.trim().split(",").map(v => v.trim()).filter(v => v)

        notificationEmails: this.notificationEmails.trim().split(",").map(v => v.trim()).filter(v => v),
        notificationChannel: this.notificationChannel || "email"
      })
          .then(() => {
            this.saving = false
            this.$gNotify("Настройки сохранены", "success")
            this.$store.dispatch("refreshUser")
            this.refresh()
          })
    }
  },
  data() {
    return {
      dataLoading: false,
      saving: false,

      // Sending time restriction
      timeRestrictionFrom: moment().toDate(),
      timeRestrictionTo: moment().toDate(),
      enableTimeLimitation: false,

      // Message deduplication settings
      sameMessageRestriction: "",
      enableSameMessageLimitation: false,

      // Daily limit for sending same message to same recipient
      dailyLimit: "",
      enableMaxMessagesLimitation: false,

      // Daily spending limitation
      enableDailySpendingLimitation: false,
      dailySpendingLimitationType: "1",
      dailySpendingLimitationAbsolute: "",
      dailySpendingLimitationRelative: "",
      // dailySpendingNotificationEmails: "",

      // Low balance notification settings
      enableLowBalanceNotifications: false,
      lowBalanceNotice: "",
      lowBalanceNoticeInterval: "",
      // lowBalanceNoticeEmails: "",
      sendNotificationsViaSms: false,
      lowBalanceNoticePhones: "",


      enableUnpaidMessagesNotification: false,
      // unpaidMessagesNoticeEmails: "",


      notificationEmails: "",
      notificationChannel: "email",


      utcTimeShift: "",
      statusReceiveUrl: "",
      inboxReceiveUrl: "",
      defaultSenderName: "",

      senderNamesOpts: [],
      tenDaysAverageSpending: 0,
      todaysSpendings: 0,
      visiblePanel: "settings"
    }
  }
}

</script>