
let getTgBotSubscriptionLink = {
  methods: {
    getTgBotSubscriptionLink(callback){
      this.axios.get("/user/getTgBotSubscriptionLink")
        .then(resp => {
          callback(resp.data.link)
        })
    }
  }
}

module.exports = getTgBotSubscriptionLink